import * as React from "react";
import Index from "../components/views/home";
import { Helmet } from "react-helmet";
const IndexPage = ({ location }) => {
  return (
    <>
     <Helmet>
        <title>Home</title>
        <meta name="facebook-domain-verification" content="l6gqqlxrtg6dby8g4qfnl0rda23d1k" />
      </Helmet>
      <Index location={location} />
    </>
  );
};

export default IndexPage;
