import React from "react";
import Layout from "../../layout/Layout";
import Header from "./Header";

const index = ({ location }) => {
  return (
    <Layout location={location}>
      <Header />
    </Layout>
  );
};

export default index;
