import { Link } from "gatsby";
import { BsArrowRight } from "react-icons/bs";
import React from "react";

const Header = () => {
  return (
    <div className="w-full  flex" style={{ height: "calc(100vh - 97px"}}>
      <div className="main-container my-auto">
        <h1 className="text-primary text-[40px] leading-[42px] md:text-[60px] md:leading-[70px] font-bold mb-1 capitalize">
          Business Architecture
        </h1>
        <h1 className="text-dark text-[40px] md:leading-[40px] md:text-[60px] leading-10 font-bold capitalize mb-10">
          consultancy firm
        </h1>
        <p className="capitalize text-[#888888] mb-2">
          Delivering Strategic Change to Transform Your Business
        </p>
        <Link to="/services" className="text-primary text-[15px] cursor-pointer capitalize flex items-center gap-4">
            <span className="text-[16px]">
              <BsArrowRight />
            </span>
            See Our Services
        </Link>
      </div>
    </div>
  );
};

export default Header;
